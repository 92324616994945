import React from "react";
import smallImg from "../../../../src/images/sets.jpg";
import smallImgJobsite from "../../../../src/images/sets-jobsite.jpg";
import smallImgJobsiteFR from "../../../../src/images/sets-jobsite-fr.jpg";
import { outputEnFr } from "../../common/HelperFunctions";
import { isEnvironmentBFERENTAL } from "../../common/Constants";

const SmallEquip = (props: { lang: string }) => {
  const smallEquipContent =
    props.lang === "en"
      ? `<a target="_blank" href="https://www.battlefieldequipment.ca/small-equipment-tool-sell-off/"><img class="img-fluid" src="${smallImg}" alt="small equipment tool sell-off" /></a>`
      : `<a target="_blank" href="https://www.battlefieldequipment.ca/fr/vente-de-petits-equipments-outils"><img class="img-fluid" src="${smallImg}" alt="small equipment tool sell-off" /></a>`;
  const smallEquipContentJobsite =
    props.lang === "en"
      ? `<a target="_blank" href="https://www.jobsiteequipment.ca/small-equipment-tool-sell-off/"><img class="img-fluid" src="${smallImgJobsite}" alt="small equipment tool sell-off jobsite" /></a>`
      : `<a target="_blank" href="https://www.jobsiteequipment.ca/fr/vente-de-petits-equipments-outils"><img class="img-fluid" src="${smallImgJobsiteFR}" alt="small equipment tool sell-off jobsite french" /></a>`;
  return (
    <>
      <section className="small-equip">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <span
                dangerouslySetInnerHTML={{
                  __html: outputEnFr(
                    "<h2><span>Small Equipment</span> & Tool Sell-Off</h2>",
                    "<h2><span>VENTE DE PETITS</span> & EQUIPMENTS & OUTILS</span></h2>",
                    props.lang
                  ),
                }}
              />
              <span
                dangerouslySetInnerHTML={{
                  __html: outputEnFr(
                    "<h3>Great Deals on New Units, Display Units & Demo Units</h3>",
                    "<h3>Bonnes affaires sur les nouvelles unités, les unités d'exposition et les unités de démonstration</h3>",
                    props.lang
                  ),
                }}
              />
              {isEnvironmentBFERENTAL ? (
                <span
                  dangerouslySetInnerHTML={{
                    __html:
                      props.lang === "en"
                        ? `<a target="_blank" href="https://www.battlefieldequipment.ca/small-equipment-tool-sell-off"><button className="btn">View Deals</button></a>`
                        : `<a target="_blank" href="https://www.battlefieldequipment.ca/fr/vente-de-petits-equipments-outils?state=QC"><button>Voir les offres</button></a>`,
                  }}
                />
              ) : (
                <span
                  dangerouslySetInnerHTML={{
                    __html:
                      props.lang === "en"
                        ? `<a target="_blank" href="https://www.jobsiteequipment.ca/small-equipment-tool-sell-off/"><button className="btn">View Deals</button></a>`
                        : `<a target="_blank" href="https://www.jobsiteequipment.ca/fr/vente-de-petits-equipments-outils"><button>Voir les offres</button></a>`,
                  }}
                />
              )}
            </div>
            <div className="col-lg-7">
              {isEnvironmentBFERENTAL ? (
                <span dangerouslySetInnerHTML={{ __html: smallEquipContent }} />
              ) : (
                <span
                  dangerouslySetInnerHTML={{ __html: smallEquipContentJobsite }}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SmallEquip;
